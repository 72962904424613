
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MenuItem } from '@/types/menu'
import { ElTree } from 'element-ui/types/tree'
@Component
export default class ShortcutMenu extends Vue {
  @Prop() readonly showMenu!: boolean;
  private selectList: Array<MenuItem> = []
  private selectIdList: Array<string> = []
  private defaultProps = {
    children: 'childNode',
    label: 'menuName'
  }

  @Watch('showMenu') // 获取已设置的快捷菜单
  getMenu (val: boolean) {
    if (val) {
      this.$axios.get(this.$apis.homePage.selectMenuQuickconfigByList).then(res => {
        this.selectList = res || []
        this.selectIdList = this.selectList.map(item => {
          return item.menuId
        })
      })
    }
  }

  private isSubmiting = false

  // 获取全部菜单列表
  get menuList () {
    return this.$store.state.menuList || []
  }

  $refs!: {
    tree: any;
  }

  // 菜单被点击
  treeClick (data: MenuItem, checkedList: { checkedNodes: Array<MenuItem> }) {
    const list: Array<MenuItem> = []
    // 刨除父节点
    checkedList.checkedNodes.forEach(item => {
      if (!(item.childNode && item.childNode.length)) {
        list.push(item)
      }
    })
    if (list.length <= 8) {
      this.selectList = [...list]
    } else {
      // 当存在子节点时
      if (data.childNode && data.childNode.length) {
        data.childNode.forEach(item => {
          // 清除本次选中的节点
          if (this.selectList.findIndex(cItem => cItem.menuId === item.menuId) === -1) {
            this.$refs.tree.setChecked(item, false)
          }
        })
      } else {
        // 单个菜单时清掉选中状态
        this.$refs.tree.setChecked(data, false)
      }
      this.$message.warning('最多只能选择8个')
    }
  }

  // 删除已选菜单
  deleteItem (index: number) {
    this.$refs.tree.setChecked(this.selectList[index].menuId, false)
    this.selectList.splice(index, 1)
  }

  onSubmit () {
    this.isSubmiting = true
    const idList = this.selectList.map(item => {
      return item.menuId
    })
    this.$axios.post(this.$apis.homePage.save, idList).then(res => {
      this.$message.success('快捷菜单设置成功')
      this.$emit('update:showMenu', false)
      this.$emit('success')
      this.isSubmiting = false
    })
  }

  // 关闭
  closeDialog () {
    this.$refs.tree.setCheckedNodes([])
    this.$emit('update:showMenu', false)
  }
}
