
import { Component, Vue, Prop } from 'vue-property-decorator'
import Echarts from 'echarts'

@Component({
  name: 'LeftModel'
})
export default class extends Vue {
  @Prop({ default: [] }) private meau!: Array<string>
  private projectManage: Array<{name: string; value: string}> = [{
    name: '项目总数',
    value: ''
  }, {
    name: '项目总面积（万㎡）',
    value: ''
  }, {
    name: '绿地率',
    value: ''
  }, {
    name: '水域率',
    value: ''
  }]

  private personManage: Array<{name: string; value: string}> = [{
    name: '工人总数：',
    value: ''
  }, {
    name: '当前排班：',
    value: ''
  }, {
    name: '当前在岗：',
    value: ''
  }, {
    name: '当前在岗率：',
    value: ''
  }]

  private onWorkPerList = []
  private dutyShow = false

  private person: {searchIndex: number; data: any} = {
    searchIndex: 0,
    data: {}
  }

  private personChart: any = null
  private patrolChart: any = null

  private patrolManage: Array<{name: string; value: string}> = [{
    name: '巡查次数：',
    value: ''
  }, {
    name: '问题总数：',
    value: ''
  }, {
    name: '未完成：',
    value: ''
  }, {
    name: '今日巡查：',
    value: ''
  }, {
    name: '今日新增：',
    value: ''
  }]

  private patrol: {searchIndex: number; data: any} = {
    searchIndex: 0,
    data: {}
  }

  // 是否有人员管理权限
  get hasPersonMeau () {
    return this.meau.includes('0')
  }

  // 是否有巡查管理权限
  get hasPatrolMeau () {
    return this.meau.includes('1')
  }

  created () {
    this.getProjectList()
    this.hasPersonMeau && this.getPersonList()
    this.hasPersonMeau && this.getPersonByTimeList()
    this.hasPatrolMeau && this.getPatrolList()
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
    this.personChart && this.personChart.dispose()
    this.patrolChart && this.patrolChart.dispose()
  }

  resize () {
    this.personChart && this.personChart.resize()
    this.patrolChart && this.patrolChart.resize()
  }

  onClick (index: number) {
    this.person.searchIndex = index
    this.setPersonChart(index)
  }

  onClick1 (index: number) {
    this.patrol.searchIndex = index
    this.setPatrolChart(index)
  }

  getProjectList () {
    this.$axios.get(this.$apis.homePage.project).then(res => {
      if (res) {
        this.projectManage[0].value = (+res.projectTotal).toLocaleString() || '--'
        this.projectManage[1].value = (+res.maintAcreage).toLocaleString() || '--'
        if (+res.maintAcreage <= 10000) {
          this.projectManage[1].name = '项目总面积（㎡）'
          this.projectManage[1].value = (+res.maintAcreage).toLocaleString() || '--'
        } else {
          this.projectManage[1].value = ((+res.maintAcreage) / 10000).toFixed(1) || '--'
        }
        this.projectManage[2].value = (res.greenPer || '--') + '%'
        this.projectManage[3].value = (res.waterPer || '--') + '%'
      }
    })
  }

  getPersonList () {
    this.$axios.get(this.$apis.homePage.worker).then(res => {
      if (res) {
        this.personManage[0].value = (+res.workerTotal).toLocaleString() || '--'
        this.personManage[1].value = (+res.arrangeTotal).toLocaleString() || '--'
        this.personManage[2].value = (+res.onWorkTotal).toLocaleString() || '--'
        this.personManage[3].value = (res.onWorkPer || '--') + '%'
        this.onWorkPerList = res.onWorkPerList || []
        this.person.data = {
          ...this.person.data,
          projectNames: res.projectNames || [],
          projectArrangeCounts: res.projectArrangeCounts || [],
          projectOnWorkCounts: res.projectOnWorkCounts || []
        }
        this.setPersonChart(0)
      }
    })
  }

  getPersonByTimeList () {
    this.$axios.get(this.$apis.homePage.workerByTime).then(res => {
      if (res) {
        this.person.data = {
          ...this.person.data,
          dates: res.dates || [],
          dateArrangeCounts: res.dateArrangeCounts || [],
          dateOnWorkCounts: res.dateOnWorkCounts || []
        }
      }
    })
  }

  getPatrolList () {
    this.$axios.get(this.$apis.homePage.patrol).then(res => {
      if (res) {
        this.patrolManage[0].value = (+res.partrolTotal).toLocaleString() || '--'
        this.patrolManage[1].value = (+res.issueTotal).toLocaleString() || '--'
        this.patrolManage[2].value = (+res.issueUnCompletedTotal).toLocaleString() || '--'
        this.patrolManage[3].value = (+res.todayPartrolTotal).toLocaleString() || '--'
        this.patrolManage[4].value = (+res.todayIssueTotal).toLocaleString() || '--'
        this.patrol.data = res
        this.setPatrolChart(0)
      }
    })
  }

  setPersonChart (tab: number) {
    const legend = ['排班', '在岗']
    const color = ['#5B8FF9', '#36CBCB']
    const shadow = ['rgba(91, 143, 249, 0.1)', 'rgba(54, 203, 203, 0.1)']
    let xAxis, yAxis, series
    if (tab === 0) {
      xAxis = this.person.data.projectNames || []
      yAxis = [this.person.data.projectArrangeCounts || [], this.person.data.projectOnWorkCounts]
      series = this.setSeries(legend, 'bar', yAxis, shadow)
    } else {
      xAxis = this.person.data.dates || []
      yAxis = [this.person.data.dateArrangeCounts || [], this.person.data.dateOnWorkCounts]
      series = this.setSeries(legend, 'line', yAxis, shadow)
    }
    this.init(color, legend, xAxis, series, 'person')
  }

  setPatrolChart (tab: number) {
    const legend = ['未完成', '已完成']
    const color = ['#FD9401', '#52C41A']
    let xAxis, yAxis, series
    if (tab === 0) {
      xAxis = this.patrol.data.dates.slice(this.patrol.data.dates.length - 7) || []
      yAxis = [this.patrol.data.dateUnCompletedCounts.slice(this.patrol.data.dateUnCompletedCounts.length - 7) || [], this.patrol.data.dateCompletedCounts.slice(this.patrol.data.dateCompletedCounts.length - 7) || []]
      series = this.setSeries1(legend, yAxis)
    } else {
      xAxis = this.patrol.data.dates || []
      yAxis = [this.patrol.data.dateUnCompletedCounts || [], this.patrol.data.dateCompletedCounts || []]
      series = this.setSeries1(legend, yAxis)
    }
    this.init(color, legend, xAxis, series, 'patrol')
  }

  init (color: Array<string>, legend: Array<string>, xAxis: Array<string>, series: any, dom: any) {
    if (dom === 'patrol') {
      this.patrolChart && this.patrolChart.clear()
    } else {
      this.personChart && this.personChart.clear()
    }
    this.$nextTick(() => {
      const chart = Echarts.init(this.$refs[dom] as any)
      const option: any = {
        color: color,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (res: Array<any>) => {
            let tip = `${res[0].name}`
            res.forEach((item, index) => {
              tip += `<br>${item.seriesName}：${item.value || item.value === 0 ? item.value : '-'}${index === 2 ? '%' : ''}`
            })
            return tip
          }
        },
        legend: {
          data: legend,
          left: 0,
          top: 0,
          itemHeight: 1,
          itemWidth: 20,
          textStyle: {
            color: '#8C8C8C',
            fontSize: 12,
            padding: [0, 10, 0, 0]
          }
        },
        grid: {
          left: 10,
          right: 0,
          top: 39,
          bottom: 10,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: (value: string) => {
                if (value.length > 8) {
                  return `${value.substring(0, 8)}...`
                } else {
                  return value
                }
              },
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            }
          }
        ],
        dataZoom: [
          {
            xAxisIndex: 0,
            realtime: true,
            show: this.getShowZoom(dom),
            showDetail: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            zoomLock: true,
            width: '100%',
            height: 12,
            type: 'slider',
            orient: 'horizontal',
            fillerColor: '#ddd',
            showDataShadow: false,
            dataBackground: {
              lineStyle: {
                width: 0,
                opacity: 1
              }
            },
            handleStyle: {
              // borderWidth: 0,
              // color: '#ddd',
              // height: 12,
              opacity: 0
            },
            startValue: 0,
            endValue: this.getDataNum(dom),
            left: 'center',
            bottom: -3
          }
        ],
        series: series
      }
      chart && chart.setOption(option)
      if (dom === 'patrol') {
        this.patrolChart = chart
      } else {
        this.personChart = chart
      }
    })
  }

  setSeries (legend: Array<string>, type: string, yAxis: string[][], shadow: Array<string>) {
    return [
      {
        name: legend[0],
        type: type || 'bar',
        symbol: 'none',
        smooth: true,
        emphasis: {
          focus: 'series'
        },
        data: yAxis[0],
        barWidth: 20,
        itemStyle: {
          normal: {
            borderWidth: 3
          }
        },
        lineStyle: {
          width: 4,
          shadowBlur: 0,
          shadowColor: shadow[0],
          shadowOffsetY: 20
        }
      },
      {
        name: legend[1],
        type: type || 'bar',
        symbol: 'none',
        smooth: true,
        emphasis: {
          focus: 'series'
        },
        data: yAxis[1],
        barWidth: 20,
        itemStyle: {
          normal: {
            borderWidth: 3
          }
        },
        lineStyle: {
          width: 4,
          shadowBlur: 0,
          shadowColor: shadow[1],
          shadowOffsetY: 20
        }
      }
    ]
  }

  setSeries1 (legend: Array<string>, yAxis: string[][]) {
    return [
      {
        name: legend[0],
        stack: '巡查管理',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: yAxis[0],
        barWidth: 20
      },
      {
        name: legend[1],
        stack: '巡查管理',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: yAxis[1],
        barWidth: 20
      }
    ]
  }

  // 是否展示柱状图dataZoom滑动
  getShowZoom (dom: any) {
    let state = false
    switch (dom) {
      case 'person':
        state = this.person.searchIndex === 0
        break
      case 'patrol':
        state = this.patrol.searchIndex === 1
        break
    }
    return state
  }

  // 柱状图默认展示个数
  getDataNum (dom: any) {
    if (this.person.searchIndex === 0 && dom === 'person') {
      return 2
    }
    return 6
  }
}
