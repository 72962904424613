
import { Component, Vue, Prop } from 'vue-property-decorator'
import Echarts from 'echarts'
import ShortcutMenu from '@/views/homePage/ShortcutMenu.vue'
import { px2vw } from '@/utils/formatData'

@Component({
  name: 'RightModel',
  components: { ShortcutMenu }
})

export default class extends Vue {
  @Prop({ default: [] }) private meau!: Array<string>
  // 资源
  private resource: { type: number; count: number } = {
    type: 0,
    count: 0
  }

  private resourceChart: any = null
  // 快捷菜单
  private menuList = []
  private showMenu = false
  private messageList: Array<{ inner: string; msg: string; readState: string}> = []

  // 是否有资源菜单权限
  get hasResource () {
    return this.meau.length
  }

  created () {
    this.hasResource && this.getResource()
    this.getMessage()
    this.getMenu()
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
    this.resourceChart && this.resourceChart.dispose()
  }

  resize () {
    this.resourceChart && this.resourceChart.resize()
  }

  // 点击快捷菜单
  onClick (url: string) {
    this.$router.push({ path: url })
  }

  getResource () {
    this.$axios.get(this.$apis.homePage.resource).then(res => {
      this.initPie(this.getResourceData(res || []))
    })
  }

  // 处理资源管理饼图数据
  getResourceData (data: any) {
    const list = [{
      name: '普通苗木',
      value: 'commonSeedlingCount'
    }, {
      name: '古树名木',
      value: 'famousSeedlingCount'
    }, {
      name: '建筑设施',
      value: 'buildFacilitiesCount'
    }, {
      name: '服务设施',
      value: 'serviceFacilitiesCount'
    }, {
      name: '景观景点',
      value: 'sceneryCount'
    }, {
      name: '智能设备',
      value: 'deviceCount'
    }]
    const dataList: any = []
    this.meau.forEach((item) => {
      const type = list[+item - 3]
      this.resource.type++
      this.resource.count += data[type.value]
      dataList.push({ name: type.name, value: data[type.value] })
    })
    return dataList
  }

  // 饼图初始化
  initPie (data: any) {
    this.$nextTick(() => {
      this.resourceChart = Echarts.init(this.$refs.resource as any)
      let num = 0
      let lastPer = ''
      const options: any = {
        tooltip: {
          show: true,
          formatter: (data: any) => {
            return data.name + ':&nbsp;' + data.percent.toFixed(1) + '%'
          }
        },
        legend: {
          orient: 'vertical',
          left: '50%',
          top: 'center',
          icon: 'circle',
          itemWidth: px2vw(8),
          itemGap: px2vw(8),
          formatter: (name: any) => {
            if (data && data.length) {
              for (let i = 0; i < data.length; i++) {
                if (name === data[i].name) {
                  let itemNum
                  if (this.resource.count === 0) {
                    itemNum = 0
                  } else {
                    itemNum = data[i].value ? (data[i].value / this.resource.count * 100).toFixed(1) : '0.0'
                    if (i < data.length - 1) {
                      num += Number(itemNum)
                    } else {
                      if (+itemNum !== 0) {
                        if (!lastPer) {
                          lastPer = (100 - num).toFixed(1)
                        }
                        itemNum = lastPer
                      }
                    }
                  }
                  return (
                    '{a|' + name + '}{b| |  ' + itemNum + '%}' + '{c|' + (data[i].value) + '}'
                  )
                }
              }
            }
          },
          textStyle: {
            color: '#000000',
            rich: {
              a: {
                color: '#595959',
                fontSize: px2vw(14),
                align: 'left',
                padding: [0, 8, 0, 8],
                width: px2vw(50),
                height: px2vw(20)
              },
              b: {
                color: '#8C8C8C',
                fontSize: px2vw(14),
                align: 'left',
                width: px2vw(90),
                height: px2vw(20)
              },
              c: {
                color: '#000000',
                fontSize: px2vw(14),
                align: 'center',
                height: px2vw(20)
              }
            }
          }
        },
        color: ['#3BA0FF', '#36CBCB', '#4DCB73', '#FAD337', '#F2637B', '#975FE4'],
        series: [
          {
            name: '',
            // avoidLabelOverlap: false,
            hoverOffset: 0,
            type: 'pie',
            radius: ['72%', '89%'],
            center: ['25%', '50%'],
            itemStyle: {
              borderWidth: 3, // 设置border的宽度有多大
              borderColor: '#fff'
            },
            label: {
              show: true,
              formatter: () => {
                return '{a|资源合计}\n' + '{b|' + (+this.resource.count).toLocaleString() + '}'
              },
              position: 'center',
              fontFamily: 'PingFangSC-Regular, PingFang SC',
              rich: {
                a: {
                  fontSize: px2vw(14),
                  fontWeight: 300,
                  color: '#999999',
                  height: 30
                },
                b: {
                  fontSize: px2vw(28),
                  fontWeight: 500,
                  color: '#000000'
                }
              }
            },
            zlevel: 4,
            data: data,
            labelLine: {
              show: false
            }
          }
        ]
      }
      this.resourceChart && this.resourceChart.setOption(options)
    })
  }

  // 获取快捷方式
  getMenu () {
    this.$axios.get(this.$apis.homePage.selectMenuQuickconfigByList).then(res => {
      this.menuList = res || []
    })
  }

  // 获取动态消息方式
  getMessage () {
    this.$axios.get(this.$apis.homePage.message).then(res => {
      this.messageList = res || []
      if (this.messageList.length > 3) {
        this.messageList = this.messageList.concat(this.messageList)
      }
      this.handleMessage()
    })
  }

  // 处理动态消息列表样式
  handleMessage () {
    this.messageList.forEach((item: { msg: string; inner: string; readState: string }) => {
      const data = item.msg.split('【')
      item.inner = ''
      if (data.length > 1) {
        data.forEach(item1 => {
          const name = item1.split('】')
          if (name.length > 1) {
            item.inner += `<span style="${item.readState === '0' ? 'color: #1890FF' : ''}">【${name[0]}】</span>${name[1]} `
          } else {
            item.inner += `${name[0]}`
          }
        })
      } else {
        item.inner = `${data[0]}`
      }
    })
  }

  // 动态消息跳转
  goDetail (item?: {msgId: string}) {
    if (item) {
      this.$router.push({ path: `homePage/detail${item.msgId}` })
    } else {
      this.$router.push({ name: 'message' })
    }
  }
}
