
import LeftModel from './Left.vue'
import MiddleModel from './Middle.vue'
import RightModel from './Right.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'HomePage',
  components: { LeftModel, MiddleModel, RightModel }
})

export default class extends Vue {
  private meauList: Array<string> = []
  private isGotMeau = false

  get resourceMenuList () {
    return this.meauList.filter(item => { return +item >= 3 && +item <= 8 })
  }

  created () {
    this.getMenu()
  }

  getMenu () {
    this.$axios.get(this.$apis.homePage.selectMenuDataShowList).then(res => {
      this.meauList = res || []
    }).finally(() => {
      this.isGotMeau = true
    })
  }
}
