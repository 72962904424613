
import { Component, Vue, Prop } from 'vue-property-decorator'
import Echarts from 'echarts'

@Component({
  name: 'MiddleModel'
})

export default class extends Vue {
  @Prop({ default: [] }) private meau!: Array<string>
  private deviceManage: Array<{name: string; value: string}> = [{
    name: '设备总数',
    value: ''
  }, {
    name: '在线设备',
    value: ''
  }, {
    name: '离线设备',
    value: ''
  }, {
    name: '在线率',
    value: ''
  }, {
    name: '未分配',
    value: ''
  }]

  private maintenanceManage: Array<{name: string; value: string}> = [{
    name: '养护工单数：',
    value: ''
  }, {
    name: '已完成：',
    value: ''
  }, {
    name: '未完成：',
    value: ''
  }, {
    name: '完成率：',
    value: ''
  }]

  private device: { data: any} = {
    data: {}
  }

  private maintenance: { searchIndex: number; data: any } = {
    searchIndex: 0,
    data: {}
  }

  private deviceChart: any = null
  private typeChart: any = null
  private stateChart: any = null

  // 是否有设备管理权限
  get hasDeviceMeau () {
    return this.meau.includes('8')
  }

  // 是否有养护管理权限
  get hasMaintenanceMeau () {
    return this.meau.includes('2')
  }

  created () {
    this.hasDeviceMeau && this.getDeviceList()
    this.hasMaintenanceMeau && this.getMaintenanceList()
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
    this.deviceChart && this.deviceChart.dispose()
    this.typeChart && this.typeChart.dispose()
    this.stateChart && this.stateChart.dispose()
  }

  resize () {
    this.deviceChart && this.deviceChart.resize()
    this.typeChart && this.typeChart.resize()
    this.stateChart && this.stateChart.resize()
  }

  onClick (index: number) {
    this.maintenance.searchIndex = index
    this.setMaintenanceChart(index)
  }

  getDeviceList () {
    this.$axios.get(this.$apis.homePage.device).then(res => {
      if (res) {
        this.deviceManage[0].value = (+res.deviceTotal).toLocaleString() || '--'
        this.deviceManage[1].value = (+res.onlineTotal).toLocaleString() || '--'
        this.deviceManage[2].value = (+res.offlineTotal).toLocaleString() || '--'
        this.deviceManage[3].value = (res.onlinePer || '--') + '%'
        this.deviceManage[4].value = (+res.unAllotTotal).toLocaleString() || '--'
        this.device.data = res
        this.setDeviceChart()
      }
    })
  }

  getMaintenanceList () {
    this.$axios.get(this.$apis.homePage.flowOrder).then(res => {
      if (res) {
        this.maintenanceManage[0].value = (+res.orderTotal).toLocaleString() || '--'
        this.maintenanceManage[1].value = (+res.orderCompletedTotal).toLocaleString() || '--'
        this.maintenanceManage[2].value = (+res.orderUnCompletedTotal).toLocaleString() || '--'
        this.maintenanceManage[3].value = (res.orderCompletedPer || '--') + '%'
        this.maintenance.data = res
        this.setMaintenanceChart(0)
      }
    })
  }

  // 设备管理-切换按钮
  setDeviceChart () {
    const legend = ['在线', '离线']
    const color = ['#00D9AD', '#B7B7B7']
    const xAxis = this.device.data.types || []
    const yAxis = [this.device.data.onlineCounts || [], this.device.data.offlineCounts]
    this.init(color, legend, xAxis, yAxis)
  }

  // 设备管理-柱状图初始化
  init (color: Array<string>, legend: Array<string>, xAxis: Array<string>, yAxis: number[][]) {
    this.deviceChart && this.deviceChart.clear()
    this.$nextTick(() => {
      this.deviceChart = Echarts.init(this.$refs.device as any)
      const option: any = {
        color: color,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (res: Array<any>) => {
            let tip = `${res[0].name}`
            res.forEach((item, index) => {
              tip += `<br>${item.seriesName}：${item.value || item.value === 0 ? item.value : '-'}${index === 2 ? '%' : ''}`
            })
            return tip
          }
        },
        legend: {
          data: legend,
          left: 0,
          top: 12,
          itemHeight: 1,
          itemWidth: 20,
          textStyle: {
            color: '#8C8C8C',
            fontSize: 12,
            padding: [0, 10, 0, 0]
          }
        },
        grid: {
          left: 10,
          right: 0,
          bottom: 10,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: (value: string) => {
                if (value.length > 8) {
                  return `${value.substring(0, 8)}...`
                } else {
                  return value
                }
              },
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            }
          }
        ],
        dataZoom: [
          {
            xAxisIndex: 0,
            realtime: true,
            show: true,
            showDetail: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            zoomLock: true,
            width: '100%',
            height: 12,
            type: 'slider',
            orient: 'horizontal',
            fillerColor: '#ddd',
            showDataShadow: false,
            dataBackground: {
              lineStyle: {
                width: 0,
                opacity: 1
              }
            },
            handleStyle: {
              // borderWidth: 0,
              // color: '#ddd',
              // height: 12,
              opacity: 0
            },
            startValue: 0,
            endValue: 3,
            left: 'center',
            bottom: -3
          }
        ],
        series: [
          {
            name: legend[0],
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: yAxis[0],
            barWidth: 20
          },
          {
            name: legend[1],
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: yAxis[1],
            barWidth: 20
          }
        ]
      }
      this.deviceChart && this.deviceChart.setOption(option)
    })
  }

  // 养护管理-切换按钮
  setMaintenanceChart (tab: number) {
    const color = ['#59CB74', '#FBD44C', '#FF587A', '#47A2FF', '#53C8D1']
    const color1 = ['#52C419', '#FD9300']
    if (tab === 0) {
      this.initPie(color, this.getTypeData(this.maintenance.data.type7List), '类型统计', 'type')
      this.initPie(color1, this.getStateData(this.maintenance.data.status7Map), '状态统计', 'state')
    } else {
      this.initPie(color, this.getTypeData(this.maintenance.data.type30List), '类型统计', 'type')
      this.initPie(color1, this.getStateData(this.maintenance.data.status30Map), '状态统计', 'state')
    }
  }

  // 养护管理-类型统计图表
  getTypeData (data: Array<{ orderTypeName: string; orderType7Count?: number; orderType30Count?: number }>) {
    const list: any = []
    data.forEach(item => {
      list.push({ name: item.orderTypeName, value: item.orderType7Count || item.orderType30Count || 0 })
    })
    return list
  }

  // 养护管理-状态统计图表
  getStateData (data: { completedCount: number; unCompletedCount: number }) {
    return [{ name: '已完成', value: data.completedCount }, { name: '未完成', value: data.unCompletedCount }]
  }

  // 养护管理-饼图初始化
  initPie (color: Array<string>, data: any, name: string, dom: any) {
    if (dom === 'type') {
      this.typeChart && this.typeChart.clear()
    } else {
      this.stateChart && this.stateChart.clear()
    }
    this.$nextTick(() => {
      const chart = Echarts.init(this.$refs[dom] as any)
      const options: any = {
        title: {
          text: name,
          left: 'center',
          bottom: '-2',
          textStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: '#999999'
          }
        },
        tooltip: {
          show: true,
          formatter: (data: any) => {
            return data.name + ':&nbsp;' + data.percent.toFixed(1) + '%'
          }
        },
        color: color,
        series: [
          {
            name: name,
            // avoidLabelOverlap: false,
            hoverOffset: 0,
            type: 'pie',
            radius: '60%',
            center: ['50%', '52%'],
            label: {
              show: false,
              position: 'center'
            },
            zlevel: 4,
            data: data,
            labelLine: {
              show: false
            }
          },
          {
            name: name,
            labelLine: {
              show: false
            },
            itemStyle: {
              opacity: 0.2,
              borderColor: '#FFFFFF',
              borderWidth: 0
            },
            type: 'pie',
            hoverAnimation: false,
            radius: ['60%', '70%'],
            center: ['50%', '52%'],
            label: {
              normal: {
                show: false
              }
            },
            data: data,
            zlevel: -1
          }
        ]
      }
      chart && chart.setOption(options)
      if (dom === 'type') {
        this.typeChart = chart
      } else {
        this.stateChart = chart
      }
    })
  }
}
